import React from "react";
import GoToTop from "./GoToTop";
import Navbar from "./Navbar";
import "../index.scss";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";

function Galeria() {
  return (
    <>
      <div className="gallery w-full flex items-center cursor-pointer hover:text-[black] duration-200 justify-center py-[6rem] md:py-[8rem]">
        <Navbar />
        <GoToTop />
        <div>
          <h1 className="text-[2.5rem] md:text-[3rem] text-center relative w-[150px] md:w-[170px] mx-auto  italic text-[white] font-montserrat font-[700] pt-[3rem]">
            Galeria
            <div className="w-[8rem] h-[4px] bg-[orangered] absolute"></div>
          </h1>
        </div>
      </div>
      <h1 className="text-[1.5rem] xm:text-[2rem] font-[500] text-center mx-auto pt-[4rem] w-[80%] md:w-[550px]">
        Foto gjat ngasjeve, eventeve, gjurmimit dhe ndërtimit të shtigjeve.
      </h1>
      <div class="galery-container w-[95%] lg:w-[90%] xl:w-[1200px] mx-auto px-5 py-2 pt-[2rem]">
        <a href="/imgs/galeri/1.jpg">
          {" "}
          <img
            src={"/imgs/galeri/1.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/2.jpg">
          {" "}
          <img
            src={"/imgs/galeri/2.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/3.jpg">
          {" "}
          <img
            src={"/imgs/galeri/3.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/4.jpg">
          {" "}
          <img
            src={"/imgs/galeri/4.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/5.jpg">
          {" "}
          <img
            src={"/imgs/galeri/5.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/6.jpg">
          {" "}
          <img
            src={"/imgs/galeri/6.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/8.jpg">
          {" "}
          <img
            src={"/imgs/galeri/8.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/9.jpg">
          {" "}
          <img
            src={"/imgs/galeri/9.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/10.jpg">
          {" "}
          <img
            src={"/imgs/galeri/10.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/11.jpg">
          {" "}
          <img
            src={"/imgs/galeri/11.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/12.jpg">
          {" "}
          <img
            src={"/imgs/galeri/12.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/13.jpg">
          {" "}
          <img
            src={"/imgs/galeri/13.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/15.jpg">
          {" "}
          <img
            src={"/imgs/galeri/15.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/16.jpg">
          {" "}
          <img
            src={"/imgs/galeri/16.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/166.jpg">
          {" "}
          <img
            src={"/imgs/166.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/144.jpg">
          {" "}
          <img
            src={"/imgs/galeri/144.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/17.jpg">
          {" "}
          <img
            src={"/imgs/galeri/17.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/18.jpg">
          {" "}
          <img
            src={"/imgs/galeri/18.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/19.jpg">
          {" "}
          <img
            src={"/imgs/galeri/19.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/20.jpg">
          {" "}
          <img
            src={"/imgs/galeri/20.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/21.jpg">
          {" "}
          <img
            src={"/imgs/galeri/21.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/22.jpg">
          {" "}
          <img
            src={"/imgs/galeri/22.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/23.jpg">
          {" "}
          <img
            src={"/imgs/galeri/23.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/24.jpg">
          {" "}
          <img
            src={"/imgs/galeri/24.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/25.jpg">
          {" "}
          <img
            src={"/imgs/galeri/25.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/26.jpg">
          {" "}
          <img
            src={"/imgs/galeri/26.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/31.jpg">
          {" "}
          <img
            src={"/imgs/galeri/31.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/28.jpg">
          {" "}
          <img
            src={"/imgs/galeri/28.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/29.jpg">
          {" "}
          <img
            src={"/imgs/galeri/29.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/30.jpg">
          {" "}
          <img
            src={"/imgs/galeri/30.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/33.jpg">
          {" "}
          <img
            src={"/imgs/galeri/33.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
        <a href="/imgs/galeri/36.jpg">
          {" "}
          <img
            src={"/imgs/galeri/36.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </a>
      </div>
      <div className="footer w-full py-[1.5rem]  flex items-center justify-center">
        <div className="text-center">
          <div className="w-[170px] mx-auto relative">
            <img src={"/imgs/logo2.png"} className="w-full h-auto" alt="" />
          </div>
          <h1 className="text-[2rem] xm:text-[3rem] font-montserrat font-[800] italic">
            MOUNTAIN BIKE{" "}
            <span className="text-[orangered] text-[3rem] xm:text-[3.6rem]">
              06
            </span>
          </h1>
          <div className="w-full mx-auto flex flex-col items-center justify-center py-[12px]">
            <h1 className="text-[1.3rem] font-bold flex items-center gap-x-[8px]">
              <BsFillTelephoneFill className="text-[1.5rem] text-[black]" />
              +383 44 552 052
            </h1>
            <h1 className="text-[1.3rem] font-bold flex items-center gap-x-[8px]">
              <AiFillMail className="text-[1.5rem] text-[black]" />
              mountainbike06@hotmail.com
            </h1>
          </div>
          <p className="text-[1.2rem] xm:text-[1.3rem] mt-[0.5rem] pt-[1rem] font-semibold text-[black] border-t-2 border-[orangered]">
            © Të gjitha të drejtat e rezervuara
          </p>
        </div>
      </div>
    </>
  );
}

export default Galeria;

import React from "react";
import "../index.scss";
import { HiOutlineLightBulb } from "react-icons/hi";
import { GoGoal } from "react-icons/go";
import { Link } from "react-router-dom";

function Info() {
  return (
    <>
      <div className="mx-auto w-[95%] h-auto py-[2rem] xm:py-[3rem]  lg:w-[90%] xl:w-[1200px] flex flex-col items-center justify-center md:justify-between md:flex-row">
        <div className="w-full flex-col md:flex-row flex items-center justify-between">
          <div className="w-[90%] xm:w-[70%] md:w-[50%] h-auto mr-0 md:mr-[1rem] py-[2rem]">
            <h1 className="text-[2.1rem] xm:text-[2.7rem] font-montserrat font-[700] leading-[2.5rem] xm:leading-[3.5rem] py-[0.5rem]">
              Mountain Bike{" "}
              <span className="text-[orangered] text-[2.5rem]  xm:text-[3.4rem] font-extrabold">
                {" "}
                06
              </span>
            </h1>
            <p className="font-roboto text-[1.1rem] font-[350]">
              Klubi ynë është epiqendra e aventurave dy-rrotëshe në zemër të
              Gjilanit. I themeluar si grup në vitin 2018 synimi ynë kryesorë
              është eksplorimi i natyrës dhe përjetimi aventurave në natyrën
              mahnitëse të vendit tonë. Ku terrenet e thyera malore bashkohen me
              sportin e ciklizmit duke u shëndrruar në një botë të mbushur me
              adrenalinë
            </p>
            <div className="pt-[1.5rem] ">
              <h1 className="font-roboto text-[23px] italic font-[550] flex items-center">
                {" "}
                <GoGoal className="text-[2.5rem] mr-[10px] text-[orangered]" />
                Misioni ynë
              </h1>
              <p className="font-roboto text-[1.1rem] font-[350] flex items-center">
                Eksplorimi i peisazheve natyrore të shumta të vendit tonë,
                gjetja e shtigjeve te reja si dhe ndertimi tyre. Duke mos u ndal
                aty per te arritur tek caku, organizmi i garave në diciplinat e
                ciklizmit malor, sic janë: Downhill, Crosscountry, Enduro etj.
                Si dhe keshtu ta prezantojmë vendin tonë me një
                kulturë sportive.
              </p>
              <h1 className="pt-[15px] font-roboto text-[23px] italic font-[550] flex items-center">
                {" "}
                <HiOutlineLightBulb className="text-[2.5rem] mr-[10px] text-[orangered]" />
                Vizioni ynë
              </h1>
              <p className="font-roboto text-[1.1rem] font-[350] flex items-center">
                Shtrihet përtej ecjes me bicikletë; synojmë të krijojmë një
                ndikim pozitiv në komunitetin tonë duke organizuar ngjarje,
                nisma dhe projekte që sjellin benefite për vendin dhe qytetarët.
              </p>
            </div>
            <div className="w-full flex items-center justify-center my-[2rem]">
              <Link to={"/Info"}>
                <button className="w-[170px] py-[0.6rem] rounded-[8px] hover:text-[white] hover:bg-[orangered] duration-200 text-[orangered] border-2 border-[orangered] font-[500] text-[1.1rem]">
                  Më Shumë
                </button>
              </Link>
            </div>
          </div>
          <div className="w-full xm:w-[80%] md:w-[680px] flex flex-col xm:flex-row justify-between space-x-0 md:-space-x-[33rem]">
            <div className="info-img w-[90%] xm:w-[52%] mt-0 xm:mt-[2rem] h-[400px]">
              <img
                src={"/imgs/galeri/11.jpg"}
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
            <div className="info-img w-[90%] xm:w-[52%] mt-[1rem] xm:mt-0 h-[400px]">
              <img
                src={"/imgs/166.jpg"}
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto w-[95%] h-auto pb-[2rem] lg:w-[90%] xl:w-[1200px] flex flex-wrap flex-col items-center justify-center gap-[1rem] md:flex-row">
        <div className="w-full md:w-[370px] h-[auto] md:h-[300px] ">
          <img
            src={"/imgs/team.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </div>

        <div className="w-full md:w-[370px] h-[auto] md:h-[300px] ">
          <img
            src={"/imgs/galeri/12.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </div>
        <div className="w-full md:w-[370px] h-[auto] md:h-[300px] ">
          <img
            src={"/imgs/galeri/5.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </div>
        <div className="w-full md:w-[370px] h-[auto] md:h-[300px] ">
          <img
            src={"/imgs/13.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </div>
        <div className="w-full md:w-[370px] h-[auto] md:h-[300px] ">
          <img
            src={"/imgs/galeri/36.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </div>
        <div className="w-full md:w-[370px] h-[auto] md:h-[300px] ">
          <img
            src={"/imgs/team2.jpg"}
            className="w-full h-full object-cover rounded-[3px]"
            alt=""
          />
        </div>
      </div>
    </>
  );
}

export default Info;

import React from "react";
import "../index.scss";
// import { Link } from "react-router-dom";

function EventBanner() {
  return (
    <div className="event-banner relative h-auto pb-[15rem] py-[4rem] w-[100%]  flex flex-col items-center justify-center ">
      <div className="w-[90%] md:w-[650px] text-center mx-auto">
        {/* <h1 className="text-[1.7rem] xm:text-[3rem] text-[white] font-montserrat font-[600]">
          Eventet
        </h1>
        <h1 className="text-[2rem] xm:text-[3rem] text-[orangered] font-montserrat font-[700] italic">
          26 - 27 Tetorë 2024
        </h1> */}
      </div>
      <div className="absolute -bottom-[10rem] xm:-bottom-[7rem] mx-auto  w-[95%] h-auto  lg:w-[90%] xl:w-[1200px] flex items-center justify-center gap-[0rem] flex-wrap xm:flex-nowrap">
        <div className="event-imgs w-[50%] h-[200px] xm:w-[40%] md:w-[340px] xm:h-[330px] ">
          <img
            src={"/imgs/galeri/3.jpg"}
            className="w-full h-full object-cover"
            alt=""
          />
        </div>
        <div className="event-imgs w-[50%] h-[200px] xm:w-[40%] md:w-[340px] xm:h-[330px] ">
          <img
            src={"/imgs/12.jpg"}
            className="w-full h-full object-cover"
            alt=""
          />
        </div>
        <div className="event-imgs w-[50%] h-[200px] xm:w-[40%] md:w-[340px] xm:h-[330px] ">
          <img
            src={"/imgs/galeri/4.jpg"}
            className="w-full h-full object-cover"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default EventBanner;

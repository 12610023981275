import React from "react";
import "../index.scss";

function Sponzoret() {
  return (
    <div className="trailmap  bg-[black] w-full h-auto py-[3rem] md:py-[2rem]  ">
      <div className="w-full text-center pb-[3rem]">
        <h1 className="relative w-[220px] mx-auto text-[white] text-[2.5rem] xm:text-[2.7rem] font-montserrat font-[700]  py-[0.5rem]">
          Sponzorët
          <div className="absolute right-[6rem] w-[7rem] h-[5px] bg-[orangered]"></div>
        </h1>
      </div>
      <div className="mx-auto w-[95%]  lg:w-[90%] xl:w-[1200px] flex flex-wrap items-center justify-center gap-[1rem]">
        <div>
          <img
            src={"/imgs/clients/komuna.png"}
            className="w-[19rem] h-auto ml-[1rem]"
            alt=""
          />
        </div>
        <div>
          <img
            src={"/imgs/clients/maroa.png"}
            className="w-[11rem] h-auto"
            alt=""
          />
        </div>
        <div>
          <img
            src={"/imgs/clients/intro.png"}
            className="w-[11.4rem] h-auto"
            alt=""
          />
        </div>
        <div>
          <img
            src={"/imgs/clients/black.png"}
            className="w-[22rem] h-auto"
            alt=""
          />
        </div>{" "}
        <div>
          <img
            src={"/imgs/clients/kameleon.png"}
            className="w-[22rem] h-auto"
            alt=""
          />
        </div>
        <div>
          <img
            src={"/imgs/clients/crypto.png"}
            className="w-[18rem] h-auto"
            alt=""
          />
        </div>{" "}
        <div>
          <img
            src={"/imgs/clients/red.png"}
            className="w-[10.5rem] h-auto"
            alt=""
          />
        </div>{" "}
        <div>
          <img
            src={"/imgs/clients/prishtina.png"}
            className="w-[16rem] h-auto ml-0 xm:ml-[2.5rem]"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Sponzoret;

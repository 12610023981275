import React, { useState } from "react";
import GoToTop from "./GoToTop";
import Navbar from "./Navbar";
import "../index.scss";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";

function Galeria() {
  const [photos] = useState([
    { vid: "/imgs/gara/vid1.mp4" },
    { vid: "/imgs/gara/vid2.mp4" },
    { vid: "/imgs/gara/vid3.mp4" },
    { vid: "/imgs/gara/vid4.mp4" },
    { vid: "/imgs/gara/vid5.mp4" },
    { vid: "/imgs/gara/vid6.mp4" },
    { vid: "/imgs/gara/vid7.mp4" },
  ]);
  return (
    <>
      <div className="gallery w-full flex items-center cursor-pointer hover:text-[black] duration-200 justify-center py-[6rem] md:py-[8rem]">
        <Navbar />
        <GoToTop />
        <h1 className="text-[2.5rem] md:text-[3rem] text-center relative w-[370px] mx-auto  italic text-[white] font-montserrat font-[700] pt-[3rem]">
          Video të garës
          <div className="w-[8rem] h-[4px] bg-[orangered] absolute"></div>
        </h1>
      </div>

      <div class="video-container w-[100%] lg:w-[90%] xl:w-[1200px] mx-auto px-5 py-2 pt-[2rem]">
        {photos.map((photo) => {
          return (
            <video className="w-full h-auto" src={photo.vid} controls></video>
          );
        })}
      </div>
      <div className="footer w-full py-[1.5rem]  flex items-center justify-center">
        <div className="text-center">
          <div className="w-[170px] mx-auto relative">
            <img src={"/imgs/logo2.png"} className="w-full h-auto" alt="" />
          </div>
          <h1 className="text-[2rem] xm:text-[3rem] font-montserrat font-[800] italic">
            MOUNTAIN BIKE{" "}
            <span className="text-[orangered] text-[3rem] xm:text-[3.6rem]">
              06
            </span>
          </h1>
          <div className="w-full mx-auto flex flex-col items-center justify-center py-[12px]">
            <h1 className="text-[1.3rem] font-bold flex items-center gap-x-[8px]">
              <BsFillTelephoneFill className="text-[2rem] text-[orangered]" />
              +383 44 552 052
            </h1>
            <h1 className="text-[1.3rem] font-bold flex items-center gap-x-[8px]">
              <AiFillMail className="text-[2rem] text-[orangered]" />
              mountainbike06@hotmail.com
            </h1>
          </div>
          <p className="text-[1.1rem] xm:text-[1.3rem] mt-[0.5rem] pt-[1rem] font-semibold text-[black] border-t-2 border-[orangered]">
            © Të gjitha të drejtat e rezervuara
          </p>
        </div>
      </div>
    </>
  );
}

export default Galeria;

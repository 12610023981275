import React from "react";
import Navbar from "./Navbar";
import GoToTop from "./GoToTop";
import "../index.scss";
import { TiTick } from "react-icons/ti";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";

function RrethNesh() {
  return (
    <>
      <div className="about w-full flex items-center cursor-pointer hover:text-[black] duration-200 justify-center py-[6rem] md:py-[8rem]">
        <Navbar />
        <GoToTop />
        <div>
          <h1 className="text-[2.5rem] md:text-[3rem] text-center relative w-[240px] md:w-[300px] mx-auto  italic text-[white] font-montserrat font-[700] pt-[3rem]">
            Rreth <span className="text-[orangered]">Nesh</span>
            <div className="w-[8rem] h-[4px] bg-[orangered] absolute"></div>
          </h1>
        </div>
      </div>{" "}
      <div className="w-[95%] md:w-[70%] shadow-xl bg-[white]  -mt-[3rem] py-[2rem] xm:py-[4rem] mx-auto text-center">
        <h1 className="text-[1.7rem] xm:text-[2rem] relative font-montserrat font-[700] mx-auto w-[220px] xm:w-[250px]">
          Kush Jemi Ne{" "}
          <div className="w-[8rem] h-[4px] bg-[orangered] left-[10px] absolute"></div>
        </h1>
        <div className="w-full px-[1.2rem] xm:px-[3.4rem]">
          <p className="text-[1.1rem] xm:text-[1.2rem] pt-[3rem] text-left">
            Mirë se vini në klubin e ciklizmit malorë Mountain Biking 06 ! I
            formuar si grup në vitin 2018 kurse si OJQ në 2022, ne jemi një
            komunitet entuziast që ndajmë dashurinë dhe pasionin për natyrën,
            aventurat dhe adrenalinën e ciklizmit në terrene të ashpra dhe
            malore. Klubi ynë është i përkushtuar për të promovuar sportin
            fantastik të ciklizmit malorë dhe për ti shërbyer komunitetit duke
            ofruar mundësi për të shijuar këtë sport në shoqërinë e ciklistëve
            të talentuar.
          </p>
          <p className="text-[1.1rem] xm:text-[1.2rem] pt-[2rem] text-left">
            Nga fillestarët tek profesionistët e këtij sporti ne synojmë të
            përbëhemi nga të gjitha nivelet e ciklistëve. Prej qëllimeve tona
            është të bëjmë ciklizmin malorë të mundshëm për të gjithë, duke
            organizuar ngasje në grupe dhe duke zbuluar dhe ndërtuar shtigje të
            reja të ciklizmit.
          </p>
          <p className="text-[1.1rem] xm:text-[1.2rem] pt-[2rem] text-left">
            Klubi ynë është në bashkpunim dhe i përkrahur edhe nga instuticionet
            vendore dhe komunale, përmes të cilave ka në plan edhe realizimin e
            disa projekteve për promovimin e këtij sporti. Ndër projektet që ne
            jemi të fokusuar janë: Organizimi i garave dhe eventeve të ndryshme,
            ndërtimi i pumptrack-ut në qytetin tonë si dhe gjuetia e
            shtigjeve(trail-hunting) - zbulimi dhe ndërtimi i shtigjeve të
            ndryshme malore.
          </p>
        </div>
        <div className="w-full h-auto pt-[3rem]">
          <img
            src={"/imgs/galeri/9.jpg"}
            className="w-full h-[40%] object-cover"
            alt="img"
          />
        </div>

        <div className="w-full py-[2rem] px-[1.2rem] xm:px-[3.4rem]">
          <h1 className="text-[1.7rem] xm:text-[2rem] relative font-montserrat font-[700] mx-auto w-[220px] xm:w-[200px]">
            Misioni ynë
            <div className="w-[8rem] h-[4px] bg-[orangered] left-[10px] absolute"></div>
          </h1>

          <p className="text-[1.2rem] xm:text-[1.4rem] font-[600] pt-[2rem] text-left">
            Në Mountain Biking <span className="text-[orangered]">06</span> ,
            misioni ynë është të:
          </p>

          <ul className=" text-left mx-auto ">
            <li className="text-[1.1rem] px-[1rem] xm:text-[1.2rem] pt-[1rem] text-left">
              <span className="text-[orangered]  font-[600] flex items-center cursor-pointer hover:text-[black] duration-200">
                <TiTick />
                Promovojmë Sportin:
              </span>{" "}
              Synojmë të ofrojmë më shumë njerëz në botën e bicikletave malore
              dhe të inkurajojmë një stil të shëndetshëm dhe aktiv të jetës.
            </li>
            <li className="text-[1.1rem] px-[1rem] xm:text-[1.2rem] pt-[1.5rem] text-left">
              <span className="text-[orangered]  font-[600] flex items-center cursor-pointer hover:text-[black] duration-200">
                <TiTick />
                Eksplorojmë Natyrën:
              </span>{" "}
              Gjilani dhe Kosova në përgjithësi kanë disa prej peizazheve
              natyrore më të mahnitshme në rajon. Ne jemi në një kërkim për të
              zbuluar këto "thesare" të fshehura, dhe e bëjmë këtë duke shijuar
              pasionin tonë për ciklizmin. Në këtë mënyrë duke krijuar një
              lidhje më të thellë midis anëtarëve tanë dhe natyrës së madhe.
            </li>
            <li className="text-[1.1rem] px-[1rem] xm:text-[1.2rem] pt-[1.5rem] text-left">
              <span className="text-[orangered]  font-[600] flex items-center cursor-pointer hover:text-[black] duration-200">
                <TiTick />
                Siguria është e Parësore:
              </span>{" "}
              Siguria është e parësorja në klubin tonë. Ne ofrojmë udhëzime për
              ecjen e përgjegjshme, pajisjet e duhura dhe etikën e shtigjeve për
              të siguruar mirëqenien e të gjithëve.
            </li>
          </ul>
        </div>
      </div>
      <div className="footer w-full py-[1.5rem]  flex items-center justify-center">
        <div className="text-center">
          <div className="w-[170px] mx-auto relative">
            <img src={"/imgs/logo2.png"} className="w-full h-auto" alt="" />
          </div>
          <h1 className="text-[2rem] xm:text-[3rem] font-montserrat font-[800] italic">
            MOUNTAIN BIKE{" "}
            <span className="text-[orangered] text-[3rem] xm:text-[3.6rem]">
              06
            </span>
          </h1>
          <div className="w-full mx-auto flex flex-col items-center justify-center py-[12px]">
            <h1 className="text-[1.3rem] font-bold flex items-center gap-x-[8px]">
              <BsFillTelephoneFill className="text-[1.5rem] text-[black]" />
              +383 44 552 052
            </h1>
            <h1 className="text-[1.3rem] font-bold flex items-center gap-x-[8px]">
              <AiFillMail className="text-[1.5rem] text-[black]" />
              mountainbike06@hotmail.com
            </h1>
          </div>
          <p className="text-[1rem] xm:text-[1.3rem] mt-[0.5rem] pt-[1rem] font-semibold text-[black] border-t-2 border-[orangered]">
            © Të gjitha të drejtat e rezervuara
          </p>
        </div>
      </div>
    </>
  );
}

export default RrethNesh;

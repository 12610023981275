import React from "react";
import Navbar from "./Navbar";
import GoToTop from "./GoToTop";
import "../index.scss";
import { Link } from "react-router-dom";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";

function DownHill() {
  return (
    <>
      <div className="events w-full flex items-center cursor-pointer hover:text-[black] duration-200 justify-center py-[6rem] md:py-[8rem]">
        <Navbar />
        <GoToTop />
        <div>
          <h1 className="text-[2.3rem] md:text-[3rem] text-center relative mx-auto  italic text-[white] font-montserrat font-[700] pt-[3rem]">
            Gjilani Downhill Cup 2023
            <div className="ml-[4rem] w-[8rem] h-[4px] bg-[orangered] absolute"></div>
          </h1>
        </div>
      </div>{" "}
      <div className="w-[100%] md:w-[80%] px-[1rem] md:px-[5rem] shadow-xl bg-[white]  -mt-[3rem] py-[2rem] xm:py-[4rem] mx-auto text-center">
        <p className="text-[1.1rem] xm:text-[1.2rem] pt-[0.5rem] text-left">
          Më datat 21 dhe 22 tetor, është mbajtur Gara e parë e stilit downhill
          në Gjilan, fshatin Stanishor. Nëngrupi jonë Wolf Pack miret enkas me
          diciplinen e Downhill. Të njejtit kanë qenë dhe organizatorë të ketij
          projekti ku edhe e realizuan me sukses.
        </p>
        <p className="text-[1.1rem] xm:text-[1.2rem] pt-[0.5rem] text-left">
          Në programin e garuesve kanë qenë të përfshira të gjitha gjërat e
          nevojshme: pijet ,ushqimi ,fjetja dhe transporti i garuesve për në
          start. Garuesit kan qenë të shumtë nga Kosova por edhe nga rajoni. Ku
          edhe ka qenë një garë mjaft sfiduese për të gjithë.
        </p>
        <p className="text-[1.1rem] xm:text-[1.2rem] pt-[0.5rem] text-left">
          Shpresojmë dhe synojmë që kjo garë tashmë të përvjetsohet dhe të
          shtohet në kalendarin e aktiviteteve në komunën tonë.
        </p>
        <div className="w-full flex flex-col md:flex-row items-center justify-center space-y-[3rem] md:space-y-0 space-x-0 md:space-x-[4rem] mt-[4rem]">
          <Link
            className="w-[100%] sm:w-[90%] md:w-[450px] h-[340px] md:h-[350px]  "
            to="/foto"
          >
            <div className="cursor-pointer foto flex items-center justify-center rounded-[15px] relative w-full h-full">
              <h1 className="absolute text-[45px] text-[white] font-semibold">
                FOTO
              </h1>
            </div>
          </Link>
          <Link
            to="/video"
            className="w-[100%] sm:w-[90%] md:w-[450px] h-[340px] md:h-[350px] "
          >
            <div className="cursor-pointer video flex items-center justify-center rounded-[15px]  relative w-full h-full">
              <h1 className="absolute text-[45px] text-[white] font-semibold">
                VIDEO
              </h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="w-full py-[2rem] ">
        <h1 className="text-[1.7rem] xm:text-[2rem] relative font-montserrat font-[700] mx-auto w-[200px]  pt-[3rem]">
          Rezultatet
          <div className="w-[8rem] h-[4px] bg-[orangered] left-[10px] absolute"></div>
        </h1>
        <p className="text-[20px] xm:text-[25px] py-[1rem] text-center">
          Për të parë të gjitha rezultatet{" "}
          <a
            className="text-[orangered]"
            target="blank"
            href="https://my.raceresult.com/267010/live?fbclid=IwAR1WR54So6w9gP1vc5ak82GLQQnihcdKG4_hd2OPKnqEiVnKrWD9LKqjNSA"
          >
            klikoni këtu
          </a>
        </p>
      </div>
      <div className="w-[90%] mx-auto">
        <img
          src={"/imgs/gara/Untitled.png"}
          className="w-full h-auto hidden xm:block"
          alt=""
        />
        <img
          src={"/imgs/gara/phone1.png"}
          className="w-full h-auto block xm:hidden"
          alt=""
        />
      </div>
      <div className="w-[90%] pt-[2rem] mx-auto">
        <img
          src={"/imgs/gara/Untitled1.png"}
          className="w-full h-auto hidden xm:block"
          alt=""
        />
        <img
          src={"/imgs/gara/phone2.png"}
          className="w-full h-auto block xm:hidden"
          alt=""
        />
      </div>
      <div className="w-[90%] pt-[2rem] mx-auto">
        <img
          src={"/imgs/gara/Untitled2.png"}
          className="w-full h-auto  hidden xm:block"
          alt=""
        />
        <img
          src={"/imgs/gara/phone3.png"}
          className="w-full h-auto block xm:hidden"
          alt=""
        />
      </div>
      <div className="w-[90%] pt-[2rem] mx-auto">
        <img
          src={"/imgs/gara/Untitled4.png"}
          className="w-full h-auto  hidden xm:block"
          alt=""
        />
        <img
          src={"/imgs/gara/phone4.png"}
          className="w-full h-auto block xm:hidden"
          alt=""
        />
      </div>
      <h1 className="text-[1.7rem] xm:text-[2rem] relative font-montserrat font-[700] mx-auto w-[330px]  pt-[3rem]">
        Sponzorët e Garës
        <div className="w-[8rem] h-[4px] bg-[orangered] left-[10px] absolute"></div>
      </h1>
      <div className="mb-[2rem] mx-auto w-[95%] lg:w-[90%] xl:w-[1200px] flex items-center justify-center flex-wrap pt-[2rem] gap-[15px]">
        <div className="w-full h-[300px] xm:w-[250px] xm:h-[250px] rounded-[15px]">
          <img
            src={"/imgs/clients/untitled.png"}
            className="w-full h-full rounded-[15px] object-contain"
            alt="img"
          />
        </div>
        <div className="w-full h-[300px] xm:w-[250px] xm:h-[250px] rounded-[15px]">
          <img
            src={"/imgs/clients/untitled1.png"}
            className="w-full h-full rounded-[15px] object-contain"
            alt="img"
          />
        </div>
        <div className="w-full h-[300px] xm:w-[250px] xm:h-[250px] rounded-[15px]">
          <img
            src={"/imgs/clients/untitled2.png"}
            className="w-full h-full rounded-[15px] object-contain"
            alt="img"
          />
        </div>
        <div className="w-full h-[300px] xm:w-[250px] xm:h-[250px] rounded-[15px]">
          <img
            src={"/imgs/clients/untitled3.png"}
            className="w-full h-full rounded-[15px] object-contain"
            alt="img"
          />
        </div>
        <div className="w-full h-[300px] xm:w-[250px] xm:h-[250px] rounded-[15px]">
          <img
            src={"/imgs/clients/untitled4.png"}
            className="w-full h-full rounded-[15px] object-contain"
            alt="img"
          />
        </div>
        <div className="w-full h-[300px] xm:w-[250px] xm:h-[250px] rounded-[15px]">
          <img
            src={"/imgs/clients/untitled5.png"}
            className="w-full h-full rounded-[15px] object-contain"
            alt="img"
          />
        </div>
        <div className="w-full h-[300px] xm:w-[250px] xm:h-[250px] rounded-[15px]">
          <img
            src={"/imgs/clients/untitled6.png"}
            className="w-full h-full rounded-[15px] object-contain"
            alt="img"
          />
        </div>
        <div className="w-full h-[300px] xm:w-[250px] xm:h-[250px] rounded-[15px]">
          <img
            src={"/imgs/clients/untitled7.png"}
            className="w-full h-full rounded-[15px] object-contain"
            alt="img"
          />
        </div>
        <div className="w-full h-[300px] xm:w-[250px] xm:h-[250px] rounded-[15px]">
          <img
            src={"/imgs/clients/untitled8.png"}
            className="w-full h-full rounded-[15px] object-contain"
            alt="img"
          />
        </div>
        <div className="w-full h-[300px] xm:w-[250px] xm:h-[250px] rounded-[15px]">
          <img
            src={"/imgs/clients/untitled9.png"}
            className="w-full h-full rounded-[15px] object-contain"
            alt="img"
          />
        </div>
      </div>
      <div className="footer w-full py-[1.5rem]  flex items-center justify-center">
        <div className="text-center">
          <div className="w-[170px] mx-auto relative">
            <img src={"/imgs/logo2.png"} className="w-full h-auto" alt="" />
          </div>
          <h1 className="text-[2rem] xm:text-[3rem] font-montserrat font-[800] italic">
            MOUNTAIN BIKE{" "}
            <span className="text-[orangered] text-[3rem] xm:text-[3.6rem]">
              06
            </span>
          </h1>
          <div className="w-full mx-auto flex flex-col items-center justify-center py-[12px]">
            <h1 className="text-[1.3rem] font-bold flex items-center gap-x-[8px]">
              <BsFillTelephoneFill className="text-[1.5rem] text-[black]" />
              +383 44 552 052
            </h1>
            <h1 className="text-[1.3rem] font-bold flex items-center gap-x-[8px]">
              <AiFillMail className="text-[1.5rem] text-[black]" />
              mountainbike06@hotmail.com
            </h1>
          </div>
          <p className="text-[1rem] xm:text-[1.3rem] mt-[0.5rem] pt-[1rem] font-semibold text-[black] border-t-2 border-[orangered]">
            © Të gjitha të drejtat e rezervuara
          </p>
        </div>
      </div>
    </>
  );
}

export default DownHill;

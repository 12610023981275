import React from "react";
import Navbar from "./Navbar";
import GoToTop from "./GoToTop";
import "../index.scss";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";

function RrethNesh() {
  return (
    <>
      <div className="events w-full flex items-center cursor-pointer hover:text-[black] duration-200 justify-center py-[6rem] md:py-[8rem]">
        <Navbar />
        <GoToTop />
        <div>
          <h1 className="text-[2.5rem] md:text-[3rem] text-center relative w-[230px] md:w-[200px] mx-auto  italic text-[white] font-montserrat font-[700] pt-[3rem]">
            Pumptrack
            <div className="w-[8rem] h-[4px] bg-[orangered] absolute"></div>
          </h1>
        </div>
      </div>{" "}
      {/*  */}
      <div className="w-full py-[2rem]">
        <div className="w-[95%] lg:w-[90%] xl:w-[1200px] mx-auto py-[2rem]">
          <div className="w-full h-[400px] xm:h-[500px] md:[550px]">
            <img
              src={"/imgs/pumptrack3.jpg"}
              className="w-full h-full object-cover"
              alt="pumptrack"
            />
          </div>
          <h1 className="text-[25px] xm:text-[30px] font-bold text-[orangered] text-center py-[1rem]">
            Ndertimi i Pumptrack-ut për të rinjtë dhe të rejat në qytetin tonë
          </h1>
          <p className="text-[1.1rem] xm:text-[1.2rem] pt-[1rem] text-left">
            Mountain Biking 06, si organizatë joqeveritare, kemi për qëllim
            promovimin e ciklizmit në Gjilan dhe Kosovë. Si një komunitet
            entuziastësh të natyrës dhe sportit, ne synojmë të sjellim më afër
            rininë me natyrën përmes ciklizmit duke krijuar infrastrukturë për
            aktivitete të shëndetshme dhe argëtuese.{" "}
          </p>
          <p className="text-[1.1rem] xm:text-[1.2rem] pt-[1rem] text-left">
            Në këtë kontekst, ne jemi në përgaditje e sipër për ndërtimin e një
            pump track për rininë në Lagjen Dardania, afër Parkut Dardania, në
            një zonë që është duke u zhvilluar pranë Shtëpisë së Kulturës. Ky
            projekt do të shërbejë si një hapësirë rekreative për rininë
            qytetit, duke ofruar një ambient të sigurt dhe të kontrolluar për të
            praktikuar aftësitë e tyre në çiklizëm.
          </p>

          <h1 className="text-[24px] font-bold text-[orangered] mt-[3rem]">
            Objektivat e projektit
          </h1>
          <ul className="w-[80%] mx-[auto] space-y-[20px] py-[2rem]">
            <li className="text-[1.1rem] xm:text-[1.2rem]">
              <span className="font-medium">
                {" "}
                Zhvillimi i Infrastrukturës Sportive:{" "}
              </span>
              Zhvillimi i Infrastrukturës Sportive: Projekti do të kontribuojë
              në zhvillimin e infrastrukturës sportive në Gjilan, duke shtuar
              një hapësirë rekreative për të rinjtë e qytetit.
            </li>
            <li className="text-[1.1rem] xm:text-[1.2rem]">
              <span className="font-medium">
                Promovimi i shëndetit publik:{" "}
              </span>
              Duke inkurajuar aktivitetet fizike dhe argëtimin në natyrë, ky
              projekt do të ndihmojë në përmirësimin e shëndetit fizik dhe
              mendor të të rinjëve dhe të rejave në komunitet.
            </li>
            <li className="text-[1.1rem] xm:text-[1.2rem]">
              <span className="font-medium">
                Rritja e ndërgjegjësimit për ciklizmin:{" "}
              </span>
              Projekti do të rrisë interesin dhe pjesëmarrjen në sportin e
              ciklizmit, duke krijuar mundësi për të ardhmen e këtij sporti në
              qytetin tonë.
            </li>
          </ul>

          <p className="text-[1.1rem] xm:text-[1.2rem] mt-[2rem] xm:mt-[3rem]">
            <span className="text-[orangered] font-medium">
              Mountain Biking 06
            </span>{" "}
            është i përkushtuar për të realizuar këtë projekt duke besuar në
            mbështetjen e Komunës së Gjilanit, ne synojmë të krijojmë një
            ambient ku rina mund të zhvillojnë aftësitë e tyre sportive dhe të
            shijojnë këtë Pump track në mënyrë të sigurt dhe të organizuar
          </p>

          <div className="w-full gap-y-[10px] md:gap-0 flex items-center justify-between mt-[2rem] md:flex-row flex-col">
            <img
              src={"/imgs/pumptrack1.jpg"}
              alt="pmtrck"
              className="w-full md:w-[47%] h-[300px] xm:h-auto md:h-[350px] obejct-cover"
            />
            <img
              src={"/imgs/pumptrack2.jpg"}
              alt="pmtrck"
              className="w-full md:w-[47%] h-[300px] xm:h-auto md:h-[350px] obejct-cover"
            />
          </div>
        </div>
      </div>
      {/*  */}
      <div className="footer w-full py-[1.5rem]  flex items-center justify-center">
        <div className="text-center">
          <div className="w-[170px] mx-auto relative">
            <img src={"/imgs/logo2.png"} className="w-full h-auto" alt="" />
          </div>
          <h1 className="text-[2rem] xm:text-[3rem] font-montserrat font-[800] italic">
            MOUNTAIN BIKE{" "}
            <span className="text-[orangered] text-[3rem] xm:text-[3.6rem]">
              06
            </span>
          </h1>
          <div className="w-full mx-auto flex flex-col items-center justify-center py-[12px]">
            <h1 className="text-[1.3rem] font-bold flex items-center gap-x-[8px]">
              <BsFillTelephoneFill className="text-[1.5rem] text-[black]" />
              +383 44 552 052
            </h1>
            <h1 className="text-[1.3rem] font-bold flex items-center gap-x-[8px]">
              <AiFillMail className="text-[1.5rem] text-[black]" />
              mountainbike06@hotmail.com
            </h1>
          </div>
          <p className="text-[1rem] xm:text-[1.3rem] mt-[0.5rem] pt-[1rem] font-semibold text-[black] border-t-2 border-[orangered]">
            © Të gjitha të drejtat e rezervuara
          </p>
        </div>
      </div>
    </>
  );
}

export default RrethNesh;

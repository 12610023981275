import React from "react";
import Navbar from "./Navbar";
import Info from "./Info";
import "../index.scss";
import EventBanner from "./EventBanner";
import Sponzoret from "./Sponzoret";
import GoToTop from "./GoToTop";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";
import { Link } from "react-router-dom";
import { LuMoveRight } from "react-icons/lu";

function Main() {
  return (
    <>
      <div className="header w-full min-h-[100vh] flex items-center justify-center py-[3rem] md:py-[0]">
        <Navbar />
        <GoToTop />
        <div className="w-[95%] h-auto mt-[3rem] lg:w-[90%] xl:w-[1200px]  flex flex-col items-center justify-center md:justify-between md:flex-row pt-[5rem]">
          <div className="hd-img w-[95%] sm:w-[70%] md:w-[500px] mr-0 md:mr-[2rem] h-[500px] bg-[gray]">
            <img
              src={"/imgs/galeri/21.jpg"}
              className=" w-full h-full object-cover"
              alt=""
            />
          </div>
          <div className="text-[white] w-[95%] md:w-[750px] pt-[3rem] md:pt-[7rem] ">
            <h1 className="text-[3rem] sm:text-[3.5rem] md:text-[4rem] xl:text-[4.5rem] text-center md:text-left font-montserrat leading-[3.3rem] sm:leading-[4rem] md:leading-[4.5rem] font-[700]">
              Përjeto emocionet e{" "}
              <span className="text-[orangered]">Çiklizmit Malorë</span>
            </h1>
          </div>
        </div>
      </div>
      <Info />
      <EventBanner />
      <div className="pb-[2rem] pt-[12rem] mx-auto w-[95%] h-auto lg:w-[90%] xl:w-[1200px] flex flex-col items-center justify-center ">
        <div className="w-full text-center">
          <h1 className="relative w-[220px] mx-auto text-[2.5rem] xm:text-[2.7rem] font-montserrat font-[700]  py-[0.5rem]">
            Projektet
            <div className="absolute right-[6.5rem] w-[7rem] h-[5px] bg-[orangered]"></div>
          </h1>
          <p className="text-[1.2rem] font-montserrat font-[400] py-[1rem] w-[90%] md:w-[60%] mx-auto">
            Klubi ynë përvec organizimeve të eventeve dhe garave, është i
            përshkushtuar për bërë të mundur ofrimin e kushteve për përhapjen e
            sportit të ciklizmit në vendin tonë.
          </p>
        </div>
        <div className="w-full flex flex-col md:flex-row justify-center gap-[3rem] py-[2rem]">
          <div class="max-w-[450px] rounded overflow-hidden shadow-lg">
            <img
              src={"/imgs/pumptrack.jpg"}
              className="w-full h-[230px] object-cover"
              alt=""
            />
            <div class="p-[15px]">
              <h1 className="text-[1.7rem] font-[500] text-center text-[orangered]">
                Pumptrack
              </h1>
              <p className="text-[1rem] py-[10px] font-[500]">
                Ndërtimi i pumptrack-ut në qytetin tonë është në plan të
                realizohet gjat vitit 2024. Ky projekt parashihet të ndërtohet
                në bashkpunim me Komunën e Gjilanit. Dhe do të jet një risi e
                mirë dhe atraktive për të rinjtë dhe entuziastët e këtij sporti.
              </p>
              <Link
                to={"/pumptrack"}
                className="text-[1.2rem] font-[500] text-[orangered] flex items-center justify-center"
              >
                Mëso më shumë
                <LuMoveRight />
              </Link>
            </div>
          </div>
          <div class="max-w-[450px] rounded overflow-hidden shadow-lg">
            <img
              src={"/imgs/trailbuilding.jpeg"}
              className="w-full h-[230px] object-cover"
              alt=""
            />
            <div class="p-[15px]">
              <h1 className="text-[1.7rem] font-[500] text-center text-[orangered]">
                Gjuetia e shtigjeve(Trail hunting)
              </h1>
              <p className="text-[1rem] py-[10px] font-[500]">
                Përkushtimi ynë për sportin e ciklizmit malorë është shkak në
                përpjekjen tonë për të gjetur dhe ndërtuar shtigje që ofrojnë
                sfida dhe adrenalinë për ciklistët. Deri tani kemi gjetur dhe
                ndërtuar disa shtigje por interesi ynë është që të vazhdojmë
                "gjujetinë" tonë të shtigjeve
              </p>
            </div>
          </div>
        </div>
      </div>
      <Sponzoret />

      <div className="footer w-full py-[1.5rem]  flex items-center justify-center">
        <div className="text-center">
          <div className="w-[170px] mx-auto relative">
            <img src={"/imgs/logo2.png"} className="w-full h-auto" alt="" />
          </div>
          <h1 className="text-[2rem] xm:text-[3rem] font-montserrat font-[800] italic">
            MOUNTAIN BIKE{" "}
            <span className="text-[orangered] text-[3rem] xm:text-[3.6rem]">
              06
            </span>
          </h1>
          <div className="w-full mx-auto flex flex-col items-center justify-center py-[12px]">
            <h1 className="text-[1.3rem] font-bold flex items-center gap-x-[8px]">
              <BsFillTelephoneFill className="text-[1.5rem] text-[black]" />
              +383 44 552 052
            </h1>
            <h1 className="text-[1.3rem] font-bold flex items-center gap-x-[8px]">
              <AiFillMail className="text-[1.5rem] text-[black]" />
              mountainbike06@hotmail.com
            </h1>
          </div>
          <p className="text-[1rem] xm:text-[1.3rem] mt-[0.5rem] pt-[1rem] font-semibold text-[black] border-t-2 border-[orangered]">
            © Të gjitha të drejtat e rezervuara
          </p>
        </div>
      </div>
    </>
  );
}

export default Main;

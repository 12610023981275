import React from "react";
import Navbar from "./Navbar";
import GoToTop from "./GoToTop";
import "../index.scss";
import { Link } from "react-router-dom";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";

function DownHill2024() {
  return (
    <>
      <div className="events w-full flex items-center cursor-pointer hover:text-[black] duration-200 justify-center py-[6rem] md:py-[8rem]">
        <Navbar />
        <GoToTop />
        <div>
          <h1 className="text-[2.3rem] md:text-[3rem] text-center relative mx-auto  italic text-[white] font-montserrat font-[700] pt-[3rem]">
            Gjilani Downhill Cup 2024
            <div className="ml-[4rem] w-[8rem] h-[4px] bg-[orangered] absolute"></div>
          </h1>
        </div>
      </div>{" "}
      <div className="video2 w-full flex items-center justify-center z-[2] pb-[3rem] mt-[0rem] md:mt-[0] bg-[#000000]">
        <video
          className="w-[100%] h-auto md:h-[100vh]"
          autoPlay
          controls
          src={"/imgs/gara2/3.mp4"}
        ></video>
      </div>
      <div className="w-[100%] md:w-[80%] px-[1rem] md:px-[5rem] shadow-xl bg-[white]  -mt-[3rem] py-[2rem] xm:py-[4rem] mx-auto text-center">
        <p className="text-[1.1rem] xm:text-[1.2rem] pt-[0.5rem] text-left">
          Më datat 26 dhe 27 tetor, është mbajtur Edicioni i dytë i garës
          Gjilani Downhill Cup në fshatin Stanishor. Me pjesmarrës dhe mysafir
          nga vendi dhe rajoni, si dhe në prezencë të kryetarit të Gjilanit
          eventi kaloi në mënyrë përfekte.
        </p>
        <p className="text-[1.1rem] xm:text-[1.2rem] pt-[0.5rem] text-left">
          Edhe ky edicion si edicioni i parë ishte i organizuar në mënyrën më të
          mirë dhe dhuroi një spektakël dhe emocione të papara.
        </p>

        <div className="w-full flex flex-col md:flex-row items-center justify-center space-y-[3rem] md:space-y-0 space-x-0 md:space-x-[4rem] mt-[4rem]">
          <Link
            className="w-[100%] sm:w-[90%] md:w-[450px] h-[340px] md:h-[350px]  "
            to="/foto2024"
          >
            <div className="cursor-pointer foto flex items-center justify-center rounded-[15px] relative w-full h-full">
              <h1 className="absolute text-[45px] text-[white] font-semibold">
                FOTO & VIDEO
              </h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="w-full py-[2rem] ">
        <h1 className="text-[1.7rem] xm:text-[2rem] relative font-montserrat font-[700] mx-auto w-[200px]  pt-[3rem]">
          Rezultatet
          <div className="w-[8rem] h-[4px] bg-[orangered] left-[10px] absolute"></div>
        </h1>
      </div>
      <div className="w-[90%] mx-auto">
        <img
          src={"imgs/gara2/rezultatet.png"}
          className="w-full h-auto "
          alt=""
        />
      </div>
      <h1 className="text-[1.7rem] xm:text-[2rem] relative font-montserrat font-[700] mx-auto w-[330px]  pt-[3rem]">
        Sponzorët e Garës
        <div className="w-[8rem] h-[4px] bg-[orangered] left-[10px] absolute"></div>
      </h1>
      <div className="mb-[2rem] mx-auto w-[95%] lg:w-[90%] xl:w-[1200px] flex items-center justify-center flex-wrap pt-[2rem] gap-[15px]">
        <div className="w-full h-[300px] xm:w-[250px] xm:h-[250px] rounded-[15px]">
          <img
            src={"/imgs/gara2/spns1.jpg"}
            className="w-full h-full rounded-[15px] object-contain"
            alt="img"
          />
        </div>
        <div className="w-full h-[300px] xm:w-[250px] xm:h-[250px] rounded-[15px]">
          <img
            src={"/imgs/gara2/spns2.jpg"}
            className="w-full h-full rounded-[15px] object-contain"
            alt="img"
          />
        </div>
        <div className="w-full h-[300px] xm:w-[250px] xm:h-[250px] rounded-[15px]">
          <img
            src={"/imgs/gara2/spns3.jpg"}
            className="w-full h-full rounded-[15px] object-contain"
            alt="img"
          />
        </div>
        <div className="w-full h-[300px] xm:w-[250px] xm:h-[250px] rounded-[15px]">
          <img
            src={"/imgs/gara2/spns4.jpg"}
            className="w-full h-full rounded-[15px] object-contain"
            alt="img"
          />
        </div>
        <div className="w-full h-[300px] xm:w-[250px] xm:h-[250px] rounded-[15px]">
          <img
            src={"/imgs/gara2/spns6.jpg"}
            className="w-full h-full rounded-[15px] object-contain"
            alt="img"
          />
        </div>
        <div className="w-full h-[300px] xm:w-[250px] xm:h-[250px] rounded-[15px]">
          <img
            src={"/imgs/gara2/spns5.jpg"}
            className="w-full h-full rounded-[15px] object-contain"
            alt="img"
          />
        </div>
        <div className="w-full h-[300px] xm:w-[250px] xm:h-[250px] rounded-[15px]">
          <img
            src={"/imgs/gara2/spns7.jpg"}
            className="w-full h-full rounded-[15px] object-contain"
            alt="img"
          />
        </div>

        <div className="w-full h-[300px] xm:w-[250px] xm:h-[250px] rounded-[15px]">
          <img
            src={"/imgs/clients/untitled8.png"}
            className="w-full h-full rounded-[15px] object-contain"
            alt="img"
          />
        </div>
        <div className="w-full h-[300px] xm:w-[250px] xm:h-[250px] rounded-[15px]">
          <img
            src={"/imgs/clients/untitled9.png"}
            className="w-full h-full rounded-[15px] object-contain"
            alt="img"
          />
        </div>
      </div>
      <div className="footer w-full py-[1.5rem]  flex items-center justify-center">
        <div className="text-center">
          <div className="w-[170px] mx-auto relative">
            <img src={"/imgs/logo2.png"} className="w-full h-auto" alt="" />
          </div>
          <h1 className="text-[2rem] xm:text-[3rem] font-montserrat font-[800] italic">
            MOUNTAIN BIKE{" "}
            <span className="text-[orangered] text-[3rem] xm:text-[3.6rem]">
              06
            </span>
          </h1>
          <div className="w-full mx-auto flex flex-col items-center justify-center py-[12px]">
            <h1 className="text-[1.3rem] font-bold flex items-center gap-x-[8px]">
              <BsFillTelephoneFill className="text-[1.5rem] text-[black]" />
              +383 44 552 052
            </h1>
            <h1 className="text-[1.3rem] font-bold flex items-center gap-x-[8px]">
              <AiFillMail className="text-[1.5rem] text-[black]" />
              mountainbike06@hotmail.com
            </h1>
          </div>
          <p className="text-[1rem] xm:text-[1.3rem] mt-[0.5rem] pt-[1rem] font-semibold text-[black] border-t-2 border-[orangered]">
            © Të gjitha të drejtat e rezervuara
          </p>
        </div>
      </div>
    </>
  );
}

export default DownHill2024;

import React, { useState } from "react";
import GoToTop from "./GoToTop";
import Navbar from "./Navbar";
import "../index.scss";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";

function Foto2024() {
  const [photos] = useState([
    { img: "/imgs/gara2/1.jpg" },
    { img: "/imgs/gara2/2.jpg" },
    { img: "/imgs/gara2/3.jpg" },
    { img: "/imgs/gara2/4.jpg" },
    { img: "/imgs/gara2/5.jpg" },
    { img: "/imgs/gara2/6.jpg" },
    { img: "/imgs/gara2/7.jpg" },
    { img: "/imgs/gara2/8.jpg" },
    { img: "/imgs/gara2/9.jpg" },
    { img: "/imgs/gara2/10.jpg" },
    { img: "/imgs/gara2/11.jpg" },
    { img: "/imgs/gara2/12.jpg" },
    { img: "/imgs/gara2/13.jpg" },
    { img: "/imgs/gara2/14.jpg" },
    { img: "/imgs/gara2/15.jpg" },
  ]);
  return (
    <>
      <div className="banner-gara-2024 w-full flex items-center cursor-pointer hover:text-[black] duration-200 justify-center py-[6rem] md:py-[8rem]">
        <Navbar />
        <GoToTop />
        <h1 className="text-[2.5rem] md:text-[3rem] text-center relative w-[470px] mx-auto  italic text-[white] font-montserrat font-[700] pt-[3rem]">
          Foto të garës 2024
          <div className="w-[8rem] h-[4px] bg-[orangered] absolute"></div>
        </h1>
      </div>

      <div class="foto-container w-[100] lg:w-[90%] xl:w-[1200px] mx-auto px-5 py-2 pt-[2rem]">
        {photos.map((photo) => {
          return (
            <a href={photo.img}>
              {" "}
              <img
                src={photo.img}
                className="w-full xm:h-full object-cover rounded-[3px] h-[18rem]"
                alt=""
              />
            </a>
          );
        })}
        <video
          className="w-full h-full object-cover"
          src={"/imgs/gara2/1.mp4"}
          controls
          muted
        ></video>
        <video
          className="w-full h-full object-cover"
          src={"/imgs/gara2/2.mp4"}
          controls
          muted
        ></video>
      </div>
      <div className="footer w-full py-[1.5rem]  flex items-center justify-center">
        <div className="text-center">
          <div className="w-[170px] mx-auto relative">
            <img src={"/imgs/logo2.png"} className="w-full h-auto" alt="" />
          </div>
          <h1 className="text-[2rem] xm:text-[3rem] font-montserrat font-[800] italic">
            MOUNTAIN BIKE{" "}
            <span className="text-[orangered] text-[3rem] xm:text-[3.6rem]">
              06
            </span>
          </h1>
          <div className="w-full mx-auto flex flex-col items-center justify-center py-[12px]">
            <h1 className="text-[1.3rem] font-bold flex items-center gap-x-[8px]">
              <BsFillTelephoneFill className="text-[2rem] text-[orangered]" />
              +383 44 552 052
            </h1>
            <h1 className="text-[1.3rem] font-bold flex items-center gap-x-[8px]">
              <AiFillMail className="text-[2rem] text-[orangered]" />
              mountainbike06@hotmail.com
            </h1>
          </div>
          <p className="text-[1.2rem] xm:text-[1.3rem] mt-[0.5rem] pt-[1rem] font-semibold text-[black] border-t-2 border-[orangered]">
            © Të gjitha të drejtat e rezervuara
          </p>
        </div>
      </div>
    </>
  );
}

export default Foto2024;
